import { asyncConnect } from 'redux-connect';
import { fromJS } from 'immutable';

import {
  connectBehance,
  importMedia,
  isUserLoaded,
  loadUser,
} from 'redux/modules/settings';
import {
  load as loadPaymentDetails,
  updatePaymentDetails,
  updatePaymentMethod,
} from 'redux/modules/paymentDetails';
import {
  createOnePageWebsite,
  updateOnePageWebsiteLinks,
  updateOnePageWebsite,
} from 'redux/modules/onePageWebsite';
import { update as updateUser } from 'redux/modules/auth';
import { load as loadSubscriptionPortalUrl } from 'redux/modules/subscriptions';
import { setErrorMessage } from 'redux/modules/errorMessage';
import { setSuccessMessage } from 'redux/modules/successMessage';

import loadable from '@loadable/component';

const SettingsBase = loadable(() => import('./SettingsBase'));

const Settings = asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState } }) => {
        const promises = [];

        if (typeof getState().auth.user !== 'undefined') {
          if (!isUserLoaded(getState())) {
            promises.push(dispatch(loadUser(getState().auth.user.id)));
          }
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    user: state.auth.user,
    settings: fromJS(state.settings),
    updatedUser: state.auth.updated,
    updatingUser: state.auth.updating,
    userEntities: fromJS(state.entities.users),
    paymentDetails: state.paymentDetails.paymentDetails,
    loadingPaymentDetails: state.paymentDetails.loading,
    loadedPaymentDetails: state.paymentDetails.loaded,
    updatingPaymentDetails: state.paymentDetails.updating,
    updatedPaymentDetails: state.paymentDetails.updated,
    paymentDetailsErrors: state.paymentDetails.paymentDetailsErrors,
    paymentMethodErrors: state.paymentDetails.paymentMethodErrors,
    subscriptionUrl: state.subscriptions.subscriptionUrl,
    loadingSubscriptionUrl: state.subscriptions.loading,
    loadedSubscriptionUrl: state.subscriptions.loaded,
    subscriptionUrlErrors: state.subscriptions.errors,
    creatingTwineWebsite: state.onePageWebsite.creating,
    createdTwineWebsite: state.onePageWebsite.created,
    updatingTwineWebsiteLinks: state.onePageWebsite.updating,
    updatedTwineWebsiteLinks: state.onePageWebsite.updated,
    twineWebsiteLinksErrors: state.onePageWebsite.updatingLinksErrors,
    twineWebsiteErrors: state.onePageWebsite.updatingWebsiteErrors,
  }),
  {
    connectBehance,
    importMedia,
    isUserLoaded,
    loadUser,
    loadPaymentDetails,
    loadSubscriptionPortalUrl,
    updatePaymentDetails,
    updatePaymentMethod,
    createOnePageWebsite,
    updateOnePageWebsiteLinks,
    updateOnePageWebsite,
    setErrorMessage,
    setSuccessMessage,
    updateUser,
  }
)(SettingsBase);

export default Settings;
