import config from '../config';
import { posthog } from 'posthog-js';

const ON_LOCAL_MACHINE = __CLOUD_ENV__ === 'local';
const NOT_ON_PRODUCTION = !/production/.test(__CLOUD_ENV__);
const ALLOW_LOCAL_TESTING = ON_LOCAL_MACHINE && config.testingGTMLocally;
let GTMBlockerEnabled = false;

export function setupTagManager() {
  const GTMBlocker = document.querySelector('meta[name="GTM-Blocker"]');
  if (GTMBlocker) GTMBlockerEnabled = GTMBlocker.content === 'enabled';

  if (GTMBlockerEnabled) {
    console.info('Google Tag Manager Disabled by GTM-Blocker');
    return;
  }

  if (ALLOW_LOCAL_TESTING) {
    console.info(
      'Setting up Tag Manager for Local Testing: ' + config.GTMEndpoint
    );
  } else if (NOT_ON_PRODUCTION) {
    console.info('Not production, so not setting up Tag Manager');
    return;
  }

  /* eslint-disable */
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', config.GTMEndpoint);
  /* eslint-enable */
}

export function setTrackingIdentity(user) {
  if (!user) {
    console.log('No user object');
    return;
  }

  if (ALLOW_LOCAL_TESTING) {
    console.info('User identity was set locally', user);
  } else if (NOT_ON_PRODUCTION) {
    console.info('If this was production, identity would be set', user);
    return;
  }

  if (__CLIENT__) {
    window.dataLayer = window.dataLayer || [];

    if (typeof posthog !== 'undefined') {
      posthog.identify(user.id.toString(), {
        username: user.username,
        email: user.email,
        userType: user.type,
      });
    }

    window.dataLayer.push({
      event: 'user-identity-set',
      userid: user.id,
      username: user.username,
      useremail: user.email,
      usertype: user.type,
      userIsPro: user.pro,
    });
  }
}

export function trackEvent(event, additionalData = {}) {
  const GTMBlocker = document.querySelector('meta[name="GTM-Blocker"]');
  if (GTMBlocker) GTMBlockerEnabled = GTMBlocker.content === 'enabled';

  if (GTMBlockerEnabled) {
    console.info(
      'Google Tag Manager Disabled by GTM-Blocker so event not tracked',
      event,
      additionalData
    );
    return;
  }

  if (ALLOW_LOCAL_TESTING) {
    console.info('An event was tracked locally', event, additionalData);
  } else if (NOT_ON_PRODUCTION) {
    console.info(
      'If this was production, an event would be tracked',
      event,
      additionalData
    );
    return;
  }

  if (
    __CLIENT__ &&
    (typeof amplitude !== 'undefined' || typeof amplitudeGTM !== 'undefined')
  ) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      additionalData,
      event: event,
    });
  } else if (__CLIENT__) {
    setTimeout(() => {
      trackEvent(event, additionalData);
    }, 1000);
  }
}

export default {
  setTrackingIdentity,
  setupTagManager,
  trackEvent,
};
