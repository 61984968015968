const CREATEONEPAGEWEBSITE = 'twineApp/onePageWebsite/CREATEONEPAGEWEBSITE';
const CREATEONEPAGEWEBSITE_SUCCESS =
  'twineApp/onePageWebsite/CREATEONEPAGEWEBSITE_SUCCESS';
const CREATEONEPAGEWEBSITE_FAIL =
  'twineApp/onePageWebsite/CREATEONEPAGEWEBSITE_FAIL';
const UPDATEONEPAGEWEBSITE = 'twineApp/onePageWebsite/UPDATEONEPAGEWEBSITE';
const UPDATEONEPAGEWEBSITE_SUCCESS =
  'twineApp/onePageWebsite/UPDATEONEPAGEWEBSITE_SUCCESS';
const UPDATEONEPAGEWEBSITE_FAIL =
  'twineApp/onePageWebsite/UPDATEONEPAGEWEBSITE_FAIL';
const UPDATEONEPAGEWEBSITETHEME =
  'twineApp/onePageWebsite/UPDATEONEPAGEWEBSITETHEME';
const UPDATEONEPAGEWEBSITETHEME_SUCCESS =
  'twineApp/onePageWebsite/UPDATEONEPAGEWEBSITETHEME_SUCCESS';
const UPDATEONEPAGEWEBSITETHEME_FAIL =
  'twineApp/onePageWebsite/UPDATEONEPAGEWEBSITETHEME_FAIL';

const initialState = {
  created: false,
  creating: false,
  updating: false,
  updated: false,
  updatingLinksErrors: {},
  updatingWebsiteErrors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATEONEPAGEWEBSITE:
      return {
        ...state,
        creating: true,
      };

    case CREATEONEPAGEWEBSITE_SUCCESS:
      return {
        ...state,
        creating: false,
        created: true,
      };

    case CREATEONEPAGEWEBSITE_FAIL:
      return {
        ...state,
        creating: false,
        created: false,
      };

    case UPDATEONEPAGEWEBSITE:
      return {
        ...state,
        updating: true,
      };

    case UPDATEONEPAGEWEBSITE_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
      };

    case UPDATEONEPAGEWEBSITE_FAIL:
      return {
        ...state,
        updating: false,
        updated: false,
        updatingLinksErrors: action.error.errors,
      };

    case UPDATEONEPAGEWEBSITETHEME:
      return {
        ...state,
        updating: true,
      };

    case UPDATEONEPAGEWEBSITETHEME_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
      };

    case UPDATEONEPAGEWEBSITETHEME_FAIL:
      return {
        ...state,
        updating: false,
        updated: false,
        updatingWebsiteErrors: action.error.errors,
      };

    default:
      return state;
  }
}

export function createOnePageWebsite(userId) {
  return {
    types: [
      CREATEONEPAGEWEBSITE,
      CREATEONEPAGEWEBSITE_SUCCESS,
      CREATEONEPAGEWEBSITE_FAIL,
    ],
    promise: (client) =>
      client.post(`/users/${userId}/website`, {
        data: { theme: 'default' },
      }),
  };
}

export function updateOnePageWebsiteLinks(userId, links) {
  return {
    types: [
      UPDATEONEPAGEWEBSITE,
      UPDATEONEPAGEWEBSITE_SUCCESS,
      UPDATEONEPAGEWEBSITE_FAIL,
    ],
    promise: (client) =>
      client.post(`/users/${userId}/website/links`, {
        data: { links: links },
      }),
    ignoreError: true,
  };
}

export function updateOnePageWebsite(userId, data) {
  return {
    types: [
      UPDATEONEPAGEWEBSITETHEME,
      UPDATEONEPAGEWEBSITETHEME_SUCCESS,
      UPDATEONEPAGEWEBSITETHEME_FAIL,
    ],
    promise: (client) =>
      client.patch(`/users/${userId}/website`, {
        data: data,
      }),
    ignoreError: true,
  };
}
