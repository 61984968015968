import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { activateAccount } from 'redux/modules/activate';
import Loader from 'components/Loader/Loader';
import useAuth from 'hooks/useAuth';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import * as Sentry from '@sentry/react';
import { Button } from 'react-bootstrap';
import Panel from 'components/Panel/Panel';
import styles from './Activate.module.scss';

const Activate = ({ location }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const key = location.query?.key ?? null;

  const activationSelect = (state) => state.activate;
  const activationState = useSelector(activationSelect);

  const { loading, error, success } = activationState;

  useEffect(() => {
    dispatch(activateAccount(key));
  }, [key, dispatch]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(new Error('Account activation failed'), {
        extra: {
          activationKey: key,
          user: user ? `${user.username} (${user.id})` : 'Not logged in',
          error: error,
        },
      });
    }
  }, [error, key, user]);

  if (success) {
    if (user) {
      localStorage.setItem(
        'successMessage',
        'Your account has been successfully activated'
      );
      window.location.href = user.links.main_relative;
    }
  }

  return (
    <Panel className={`${styles.container} container`}>
      {loading ? (
        <>
          <Loader className="mt++" loading />
          <p className="lato-bold mt++">Activating your account...</p>
        </>
      ) : error ? (
        <>
          <SvgIcon
            className="mt++"
            icon="cross_circle"
            width="50"
            height="50"
            color="red"
          />
          <p className="lato-bold mt++">
            There has been a problem activating your account. Please{' '}
            <a href="/support">contact support</a>
          </p>
        </>
      ) : success ? (
        <>
          <SvgIcon
            className="mt++"
            icon="tick_circle"
            width="50"
            height="50"
            color="green"
          />
          <p className="lato-bold mt++">
            Your account has been successfully activated
          </p>
          {!user && (
            <Button
              bsStyle="primary"
              className="mt+"
              href="/signin?referer=/me"
            >
              <strong>Sign In</strong>
            </Button>
          )}
        </>
      ) : null}
    </Panel>
  );
};

Activate.propTypes = {
  location: PropTypes.object,
};

export default Activate;
