import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import superagent from 'superagent';
import cookie from 'react-cookie';
import Helmet from 'react-helmet';
import LoadingBar from 'react-top-loading-bar';
import smoothscroll from 'smoothscroll-polyfill';
import {
  isLoaded as isAuthLoaded,
  load as loadAuth,
  updateTour,
  update as updateUser,
  loadOwnBriefs,
} from 'redux/modules/auth';
import {
  loadIntros,
  loadUnreadMessages,
  updateNewMessage,
  sendMessage,
  loadMessages,
  isIntro,
  deleteMessage,
} from 'redux/modules/messages';
import { loadPendingRatings, rate } from 'redux/modules/rating';
import {
  add as addToBanner,
  remove as removeBanner,
} from 'redux/modules/banner';
import { loadBriefsWithState } from 'redux/modules/briefs';
import { resetErrorMessage } from 'redux/modules/errorMessage';
import {
  resetSuccessMessage,
  setSuccessMessage,
} from 'redux/modules/successMessage';
import { resetHighlightElement } from 'redux/modules/highlightElement';
import {
  loadCount as loadNotificationCount,
  startedLoadingOldCount,
  finishedLoadingOldCount,
} from 'redux/modules/notifications';
import { acceptPitch, declinePitch } from 'redux/modules/briefDetails';
import { flashHighlight } from 'helpers/HelperFunctions';
import { push } from 'connected-react-router';
import { asyncConnect } from 'redux-connect';
import ScrollToTop from 'components/Utils/ScrollToTop';
import Banner from 'components/Banners/Banner/Banner';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import RatingModal from 'components/Rating/RatingModal';
import MainHeader from 'components/Header/Main/MainHeader';
import LandingHeader from 'components/Header/Landing/LandingHeader';
import StickyNavBar from 'components/Header/StickyNavBar/StickyNavBar';
import Footer from 'components/Footer/Footer';
import SmallFooter from 'components/Footer/SmallFooter';
import {
  hasBlocked as hasBlockedDesktopNotifications,
  hasPermission as hasDesktopNotificationPermission,
  isAvailable as isDesktopNotificationsAvailable,
  registerServiceWorker,
} from 'helpers/Notifications';
import Alert from 'react-s-alert';
import { renderRoutes } from 'react-router-config';
import { setupTagManager, setTrackingIdentity } from 'helpers/Analytics';
// import DevTools from 'containers/DevTools/DevTools';
import favicon from 'static/favicon.ico';
import config from '../../config';
import styles from './App.module.scss';
import '../../theme/bootstrap.overrides.scss';
import AlertHandler from '../../components/AlertHandler/AlertHandler';

@asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState } }) => {
        const promises = [];

        if (!isAuthLoaded(getState())) {
          promises.push(dispatch(loadAuth()));
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    user: state.auth.user,
    authError: state.auth.authError,
    error: state.errorMessage,
    successMessage: state.successMessage,
    highlightElement: state.highlightElement,
    messages: state.messages.messages,
    introsLeft: state.messages.introsLeft,
    introsLoading: state.messages.introsLoading,
    count: state.notifications.count,
    oldCount: state.notifications.oldCount,
    loadedCount: state.notifications.loadedCount,
    loadingCount: state.notifications.loadingCount,
    loadedOldCount: state.notifications.loadedOldCount,
    unreadMessages: state.messages.unreadMessages,
    pendingRatings: state.rating.pendingRatings,
    messageEntities: state.entities.messages,
    threadEntities: state.entities.threads,
    userEntities: state.entities.users,
    briefEntities: state.entities.briefs,
    isAsyncDataLoaded: state.reduxAsyncConnect.loaded,
    loadingBriefsWithState: state.briefs.loadingBriefsWithState,
    briefsWithState: state.briefs.briefsWithState,
    updatingUser: state.auth.updating,
  }),
  {
    pushState: push,
    resetErrorMessage,
    resetSuccessMessage,
    setSuccessMessage,
    loadIntros,
    loadNotificationCount,
    startedLoadingOldCount,
    finishedLoadingOldCount,
    updateNewMessage,
    sendMessage,
    loadMessages,
    isIntro,
    loadUnreadMessages,
    acceptPitch,
    declinePitch,
    deleteMessage,
    loadPendingRatings,
    addToBanner,
    removeBanner,
    rate,
    updateTour,
    updateUser,
    resetHighlightElement,
    loadBriefsWithState,
    loadOwnBriefs,
  }
)
class App extends Component {
  static checkUtmCampaigns(utmCampaign) {
    // The following utm_campaigns mean we believe
    // they are a buyer owning this brief and not logged in
    const campaigns = ['content-type'];

    let exists = false;
    if (Array.isArray(utmCampaign)) {
      exists = utmCampaign.some((campaign) => campaigns.indexOf(campaign) > -1);
    } else {
      exists = campaigns.indexOf(utmCampaign) > -1;
    }

    return exists;
  }

  static propTypes = {
    // children: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    location: PropTypes.object,
    user: PropTypes.object,
    pushState: PropTypes.func.isRequired,
    resetErrorMessage: PropTypes.func,
    setSuccessMessage: PropTypes.func,
    error: PropTypes.string,
    successMessage: PropTypes.string,
    highlightElement: PropTypes.string,
    loadNotificationCount: PropTypes.func,
    count: PropTypes.number,
    loadIntros: PropTypes.func,
    loadedCount: PropTypes.bool,
    loadingCount: PropTypes.bool,
    startedLoadingOldCount: PropTypes.func,
    finishedLoadingOldCount: PropTypes.func,
    oldCount: PropTypes.number,
    loadedOldCount: PropTypes.bool,
    messages: PropTypes.object,
    updateNewMessage: PropTypes.func,
    sendMessage: PropTypes.func,
    introsLeft: PropTypes.number,
    introsLoading: PropTypes.bool,
    loadMessages: PropTypes.func,
    isIntro: PropTypes.func,
    unreadMessages: PropTypes.number,
    pendingRatings: PropTypes.array,
    loadUnreadMessages: PropTypes.func,
    acceptPitch: PropTypes.func,
    declinePitch: PropTypes.func,
    threadEntities: PropTypes.object,
    messageEntities: PropTypes.object,
    userEntities: PropTypes.object,
    deleteMessage: PropTypes.func,
    briefEntities: PropTypes.object,
    loadPendingRatings: PropTypes.func,
    loadOwnBriefs: PropTypes.func,
    addToBanner: PropTypes.func,
    removeBanner: PropTypes.func,
    rate: PropTypes.func,
    updateTour: PropTypes.func,
    updateUser: PropTypes.func,
    resetSuccessMessage: PropTypes.func,
    resetHighlightElement: PropTypes.func,
    isAsyncDataLoaded: PropTypes.bool,
    loadBriefsWithState: PropTypes.func,
    loadingBriefsWithState: PropTypes.bool,
    briefsWithState: PropTypes.array,
    updatingUser: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.loadingRef = createRef(null);
    this.state = {
      showApproveFilesPrompt: false,
      showOnboardingBanner: false,
      showResendEmailBanner: false,
      isProgressShown: false,
    };
  }

  componentDidMount() {
    // polyfill for safari
    smoothscroll.polyfill();

    const {
      location: { pathname, query },
      user,
      addToBanner,
      loadIntros,
      loadUnreadMessages,
      loadNotificationCount,
      loadPendingRatings,
      loadOwnBriefs,
      loadBriefsWithState,
      finishedLoadingOldCount,
      updatingUser,
    } = this.props;

    // Set up Google Tag Manager
    if (__CLIENT__) {
      // Needs to be set before setting up Google Tag Manager (for Google Analytics)
      setupTagManager();
    }

    if (user) {
      setTrackingIdentity(user);
      loadIntros();
      loadUnreadMessages();
      loadNotificationCount();
      loadPendingRatings(user);
      loadOwnBriefs(user.id);

      if (
        typeof cookie.load('seen-approve-files-prompt') === 'undefined' &&
        !pathname.includes('/projects/b')
      ) {
        loadBriefsWithState(user.id, 1, ['paid_final_files_uploaded']);
      }

      if (__CLIENT__) {
        cookie.save('loggedIn', '1', { path: '/' });
      }

      const baseUrl =
        typeof process.env.ENDPOINT_FRONTEND !== 'undefined'
          ? `https://${process.env.ENDPOINT_FRONTEND}`
          : 'http://local.twine.net';

      superagent
        .get(`${baseUrl}/ajax_session.php?action=get_notifications_count`)
        .withCredentials()
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('Accept', 'application/json')
        .end((err, res) => finishedLoadingOldCount(res.body.count));
    }

    // Crisp
    if (process.env.ENDPOINT_FRONTEND === 'www.twine.net') {
      if (typeof window !== 'undefined') {
        /* eslint-disable */
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = 'ff9c90ab-bd2c-41f5-8f13-dfcb7733d282';

        // Note this is modified to not run onload with standard Crisp script, so we can wrap in timeout
        setTimeout(() => {
          (function () {
            var d = document;
            var s = d.createElement('script');

            s.src = 'https://client.crisp.chat/l.js';
            s.async = 1;
            d.getElementsByTagName('head')[0].appendChild(s);
          })();
          /* eslint-enable */
        }, 4000);
      }
    }

    if (__CLIENT__) {
      if (query.utm_source) {
        cookie.save('utm_source', query.utm_source, { path: '/' });
      }

      if (query.utm_medium) {
        cookie.save('utm_medium', query.utm_medium, { path: '/' });
      }

      if (query.utm_campaign) {
        cookie.save('utm_campaign', query.utm_campaign, { path: '/' });
      }

      if (query.utm_term) {
        cookie.save('utm_term', query.utm_term, { path: '/' });
      }

      if (query.utm_content) {
        cookie.save('utm_content', query.utm_content, { path: '/' });
      }

      if (query.gclid) {
        cookie.save('utm_source', 'google', { path: '/' });
        cookie.save('utm_medium', 'cpc', { path: '/' });
      }
    }

    if (__CLIENT__ && isDesktopNotificationsAvailable()) {
      registerServiceWorker();

      if (
        user &&
        !hasDesktopNotificationPermission() &&
        !hasBlockedDesktopNotifications()
      ) {
        if (
          user.type === 'seller' &&
          typeof cookie.load('seen-promote-push-notifications') ===
            'undefined' &&
          typeof cookie.load('seen-brief-invite-push') === 'undefined'
        ) {
          setTimeout(() => {
            if (user.preferences.push.brief_invite) {
              addToBanner('promote_push_notifications');
            } else {
              addToBanner('brief_invite_push');
            }
          }, 3000);
        }

        if (
          user.type === 'buyer' &&
          typeof cookie.load('seen-promote-push-notifications') === 'undefined'
        ) {
          setTimeout(() => {
            addToBanner(
              'promote_push_notifications',
              'Never miss the best creatives, enable notifications'
            );
          }, 3000);
        }
      }
    }

    if (__CLIENT__ && user && user.email_new !== '' && !updatingUser) {
      setTimeout(() => {
        addToBanner('email_confirmation', user.email_new, 'primary', false, 10);
      }, 1500);
    }

    const storedSuccessMessage = localStorage.getItem('successMessage');

    if (storedSuccessMessage) {
      this.props.setSuccessMessage(storedSuccessMessage);
      localStorage.removeItem('successMessage');
    }
  }

  componentDidUpdate(prevProps) {
    const { showResendEmailBanner, showOnboardingBanner } = this.state;

    const {
      user,
      highlightElement,
      resetHighlightElement,
      loadingBriefsWithState,
      briefsWithState,
    } = this.props;

    if (user && this.shouldShowOnboardingBanner() && !showOnboardingBanner) {
      this.showOnboardingBanner();
    }

    if (user && this.shouldShowResendEmailBanner() && !showResendEmailBanner) {
      this.showResendEmailBanner();
    }

    if (
      typeof window !== 'undefined' &&
      prevProps.highlightElement !== highlightElement &&
      highlightElement &&
      highlightElement !== ''
    ) {
      flashHighlight(highlightElement);
      setTimeout(() => {
        resetHighlightElement();
      }, 1000);
    }

    if (
      prevProps.loadingBriefsWithState &&
      !loadingBriefsWithState &&
      briefsWithState.length > 0
    ) {
      setTimeout(() => {
        this.showApproveFilesPrompt();
      }, 2000);
    }

    if (user && showOnboardingBanner && !this.shouldShowOnboardingBanner()) {
      this.hideOnboardingBanner();
    }

    if (
      user &&
      showResendEmailBanner &&
      (!this.shouldShowResendEmailBanner() || user.email_verified)
    ) {
      this.hideResendEmailBanner();
    }
  }

  shouldShowOnboardingBanner = () =>
    !/(onboarding|post|message|howitworks|whyusetwine|find|get|activate)/.test(
      this.props.location.pathname
    );

  shouldShowResendEmailBanner = () =>
    !/(onboarding|post|message|howitworks|whyusetwine|find|get|activate)/.test(
      this.props.location.pathname
    );

  showOnboardingBanner = () => {
    const { user } = this.props;

    if (!user || (user && user.type === 'buyer')) {
      return;
    }

    if (!this.shouldShowOnboardingBanner()) {
      return;
    }

    if (
      user.avatars.placeholder ||
      user.displayname === user.username ||
      user.location.method !== 'html5' ||
      user.featured_roles.length === 0 ||
      user.skills.length === 0 ||
      user.tools.length === 0 ||
      user.covers.placeholder ||
      user.stats.num_of_testimonials === 0 ||
      user.stats.num_of_credits === 0 ||
      !user.verified ||
      user.bio.length === 0 ||
      user.sectors.length === 0
    ) {
      this.props.addToBanner('onboarding', '', 'primary', false);
      this.setState({ showOnboardingBanner: true });
    }
  };

  hideOnboardingBanner = () => {
    this.setState({ showOnboardingBanner: false }, () => {
      setTimeout(() => {
        this.props.removeBanner('onboarding');
      }, 200);
    });
  };

  showResendEmailBanner = () => {
    const { user } = this.props;

    if (!user) {
      return;
    }

    if (!this.shouldShowResendEmailBanner()) {
      return;
    }

    if (!user.email_verified && user.email_new === '') {
      this.props.addToBanner(
        'email_not_verified',
        user.email,
        'warning',
        false,
        30
      );
      this.setState({ showResendEmailBanner: true });
    }
  };

  hideResendEmailBanner = () => {
    this.setState({ showResendEmailBanner: false }, () => {
      setTimeout(() => {
        this.props.removeBanner('email_not_verified');
      }, 200);
    });
  };

  showApproveFilesPrompt = () => {
    if (typeof cookie.load('seen-approve-files-prompt') === 'undefined') {
      this.setState({
        showApproveFilesPrompt: true,
      });

      cookie.save('seen-approve-files-prompt', 1);
    }
  };

  hideApproveFilesPrompt = () => {
    this.setState({
      showApproveFilesPrompt: false,
    });

    if (typeof cookie.load('seen-approve-files-prompt') === 'undefined') {
      cookie.save('seen-approve-files-prompt', 1);
    }
  };

  handleDeleteMessage = (user, messageId) => {
    const { threadEntities, messages, deleteMessage } = this.props;
    // if it's the last message in a thread
    if (threadEntities[user.id].message === messageId) {
      const nextMessageId =
        messages[user.username].messages.length > 1
          ? messages[user.username].messages[1]
          : null;
      threadEntities[user.id].message = nextMessageId;
    }

    // actually delete
    deleteMessage(user.username, messageId);
  };

  handleRating = (userId, briefId, value, comment = '') =>
    this.props.rate(userId, briefId, value, comment);

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isAsyncDataLoaded && !this.state.isProgressShown) {
      this.setState({ isProgressShown: true });
      // Progress.show();
      this.loadingRef.current.continuousStart();
    }

    if (nextProps.isAsyncDataLoaded && this.state.isProgressShown) {
      this.setState({ isProgressShown: false });
      // Progress.hide();
      this.loadingRef.current.complete();
    }

    if (
      nextProps.user &&
      nextProps.user.email_new !== '' &&
      !nextProps.updatingUser &&
      this.props.updatingUser
    ) {
      setTimeout(() => {
        this.props.addToBanner(
          'email_confirmation',
          nextProps.user.email_new,
          'primary',
          false,
          10
        );
      }, 50);
    }
  }

  isLanding = () =>
    this.props.location.pathname === '/' ||
    /(enterprise|whyusetwine|howitworks|find|get|pricing)/.test(
      this.props.location.pathname
    );

  isProject = () =>
    this.props.location.pathname === '/' ||
    /(projects)/.test(this.props.location.pathname);

  isJobs = () =>
    this.props.location.pathname === '/' ||
    /(jobs)/.test(this.props.location.pathname);

  render() {
    const {
      user,
      authError,
      // children,
      route: { routes },
      count,
      loadedCount,
      location: { pathname, query },
      oldCount,
      loadedOldCount,
      unreadMessages,
      pendingRatings,
    } = this.props;

    // Redirect to /signin if there is an authentication error
    if (authError && authError.status_code === 401) {
      if (typeof window !== 'undefined') {
        // ensure client side
        window.location.href = '/signin';
        return null; // Return null to prevent rendering the rest of the component
      }
    }

    const header = this.isLanding() ? (
      <Fragment>
        <Banner />
        <LandingHeader
          count={count}
          oldCount={oldCount}
          loadedCount={loadedCount}
          loadedOldCount={loadedOldCount}
          unreadMessages={unreadMessages}
          user={user}
          whiteText={
            pathname.indexOf('/whyusetwine') !== -1 ||
            pathname.indexOf('/howitworks') !== -1
          }
          pricingVersion={pathname === '/pricing'}
          homePath={pathname === '/'}
          creativePricing={pathname === '/pricing/creative'}
          enterpriseVersion={pathname.indexOf('/enterprise') !== -1}
          query={query}
        />
      </Fragment>
    ) : (
      <Fragment>
        <MainHeader
          user={user}
          count={count}
          loadedCount={loadedCount}
          loadedOldCount={loadedOldCount}
          oldCount={oldCount}
          unreadMessages={unreadMessages}
          isProject={this.isProject()}
          isJobs={this.isJobs()}
        />
        <Banner />
      </Fragment>
    );

    const app = (
      <Fragment>
        <ScrollToTop />
        <div className={styles.app}>
          <Helmet
            {...config.app.head}
            link={[{ rel: 'icon', type: 'image/png', href: favicon }]}
          />
          {header}
          {!user?.pro && (this.isProject() || this.isJobs()) && (
            <StickyNavBar
              user={user}
              onProjectPage={this.isProject()}
              onJobsPage={this.isJobs()}
            />
          )}

          <LoadingBar
            color={this.isLanding() ? '#2cae76' : '#2cae76'}
            ref={this.loadingRef}
          />
          <noscript>
            <div style={{ backgroundColor: '#d9534f', color: '#fff' }}>
              <div className="container text-center p">
                <strong>
                  <SvgIcon icon="alert_circle" /> Looks like you have JavaScript
                  disabled. For the full Twine experience, you will need to
                  re-enable it.
                </strong>
              </div>
            </div>
          </noscript>
          {renderRoutes(routes)}
          {pathname.indexOf('/messages') === -1 ? (
            /(post|browse)/.test(pathname) ? (
              <SmallFooter />
            ) : (
              <Footer inverse={pathname.indexOf('/companies') > -1} />
            )
          ) : (
            <noscript />
          )}
          {pendingRatings.length > 0 ? (
            <RatingModal
              pitch={pendingRatings[0].message}
              user={pendingRatings[0].message.sender}
              onRated={this.handleRating}
            />
          ) : null}
          <Alert
            stack={{ limit: 3 }}
            onClose={() => {
              this.props.resetErrorMessage();
              this.props.resetSuccessMessage();
            }}
          />
          <AlertHandler
            error={this.props.error}
            successMessage={this.props.successMessage}
            user={user}
          />
          <div
            className="fade"
            id="darken-background"
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: '#000',
              opacity: 0,
              display: 'none',
            }}
          />
        </div>
      </Fragment>
    );

    // const withInlineDevtool = (
    //   <div style={{ display: 'flex' }}>
    //     <div style={{ display: 'flex', flex: '0.7' }}>{app}</div>
    //     <div style={{ display: 'flex', flex: '0.3' }}>
    //       <DevTools />
    //     </div>
    //   </div>
    // );
    // return __DEVELOPMENT__ ? withInlineDevtool : app;
    return app;
  }
}

export default App;
