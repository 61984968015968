import { fromJS } from 'immutable';
import userSchema from '../schemas/userSchema';
import { jsonToFormData } from '../../helpers/HelperFunctions';

const LOADUSER = 'twineApp/settings/LOADUSER';
const LOADUSER_SUCCESS = 'twineApp/settings/LOADUSER_SUCCESS';
const LOADUSER_FAIL = 'twineApp/settings/LOADUSER_FAIL';
const CONNECTBEHANCE = 'twineApp/settings/CONNECTBEHANCE';
const CONNECTBEHANCE_SUCCESS = 'twineApp/settings/CONNECTBEHANCE_SUCCESS';
const CONNECTBEHANCE_FAIL = 'twineApp/settings/CONNECTBEHANCE_FAIL';
const IMPORTMEDIA = 'twineApp/settings/IMPORTMEDIA';
const IMPORTMEDIA_SUCCESS = 'twineApp/settings/IMPORTMEDIA_SUCCESS';
const IMPORTMEDIA_FAIL = 'twineApp/settings/IMPORTMEDIA_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case LOADUSER:
      return imState.setIn(['loadingUser'], true).toJS();

    case LOADUSER_SUCCESS:
      return imState
        .mergeDeep({
          id: action.result,
          loadedUser: true,
          loadingUser: false,
        })
        .toJS();

    case LOADUSER_FAIL:
      return imState
        .mergeDeep({
          loadedUser: true,
          loadingUser: false,
        })
        .toJS();

    default:
      return imState.toJS();
  }
}

export function isUserLoaded(globalState) {
  return (
    typeof globalState.settings !== 'undefined' &&
    typeof globalState.settings.loadedUser !== 'undefined' &&
    globalState.settings.loadedUser
  );
}

export function loadUser(userId) {
  return {
    types: [LOADUSER, LOADUSER_SUCCESS, LOADUSER_FAIL],
    promise: (client) =>
      client.get('/users/' + userId + '?include=social,preferences,website'),
    schema: userSchema,
  };
}

export function connectBehance(link) {
  return {
    types: [CONNECTBEHANCE, CONNECTBEHANCE_SUCCESS, CONNECTBEHANCE_FAIL],
    promise: (client) =>
      client.get(
        '/connect/behance?username=' + link + '&include=social,preferences'
      ),
    schema: userSchema,
  };
}

export function importMedia(service, roles = []) {
  const data = { _method: 'PUT' };
  if (roles.length > 0) {
    data.credits = roles;
  }
  return {
    types: [IMPORTMEDIA, IMPORTMEDIA_SUCCESS, IMPORTMEDIA_FAIL],
    promise: (client) =>
      client.post('/import/' + service, {
        data: jsonToFormData(data),
      }),
    schema: userSchema,
  };
}
