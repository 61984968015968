import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookie';
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import Button from 'react-bootstrap/lib/Button';
import { resendEmailActivation } from 'redux/modules/auth';

const EmailActivation = ({ banner }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [resent, setResent] = useState(false);

  const expires = new Date();
  expires.setDate(expires.getDate() + 1);

  const onResend = () => {
    dispatch(resendEmailActivation(user.id));
    cookie.save('resentEmail', true, { path: '/', expires: expires });
    setResent(true);
  };

  useEffect(() => {
    if (cookie.load('resentEmail')) {
      setResent(true);
    }
  }, []);

  return (
    <div>
      <span style={{ verticalAlign: 'middle' }} className="mr++">
        Please activate your email. An activation email was sent to{' '}
        <strong>{banner.message}</strong>
      </span>
      <span>
        <Button bsStyle="success" disabled={resent} onClick={() => onResend()}>
          {resent ? 'Email Resent' : 'Resend'}
        </Button>
      </span>
    </div>
  );
};

EmailActivation.propTypes = {
  banner: PropTypes.object,
};

export default EmailActivation;
