/* eslint-disable react/jsx-curly-brace-presence */

import React from 'react';
import { Redirect } from 'react-router-dom';

import App from 'containers/App/App';

import { isLoaded as isAuthLoaded, load as loadAuth } from 'redux/modules/auth';
import { get } from 'helpers/HelperFunctions';

import {
  AnswerPublicQuestion,
  Aup,
  Copyright,
  EngagementTerms,
  HowItWorks,
  Login,
  MicroProjects,
  NotFound,
  Notifications,
  Onboarding,
  OnboardingNotifications,
  PlatformTerms,
  Pricing,
  Post,
  ReportMessages,
  SignUp,
  Shortlist,
  ViewEarnings,
  ViewPayments,
  Undock,
} from 'containers/Universals';
import Home from 'containers/Home/Home';
import Portfolio from 'containers/Portfolio/Portfolio';
import Browse from 'containers/Browse/Browse';
import Categories from 'containers/Categories/Categories';

import WhyUseTwine from 'containers/LandingPages/WhyUseTwine/WhyUseTwine';
import Enterprise from 'containers/LandingPages/Enterprise/Enterprise';
import Messages from 'containers/Messages/Messages';
import Project from 'containers/Project/Project';
import Settings from 'containers/Settings/Settings';
import TestimonialsUpdate from 'containers/Testimonials/TestimonialsUpdate';
import Projects from 'containers/Projects/Projects';
import CreateProject from 'containers/TextProjects/CreateProject';
import EditProject from 'containers/TextProjects/EditProject';
import ManageProjects from 'containers/ManageProjects/ManageProjects';
import {
  BriefDetailsWrapper,
  ManageBriefsWrapper,
  ManagePitchesWrapper,
  FindRedirectWrapper,
} from './redirectWrappers';
import { getRedirRoute } from 'helpers/Redirections';
import Activate from 'containers/Activate/Activate';

export default ({ dispatch, getState }) => {
  const checkAuth = () => get(getState(), ['auth', 'user'], null);

  let user = checkAuth();

  if (!isAuthLoaded(getState())) {
    dispatch(loadAuth())
      .then(() => {
        user = checkAuth();
      })
      .catch(() => {});
  } else {
    user = checkAuth();
  }

  const isLoggedIn = () => !!getState().auth.user;

  const signInUrl = process.env.ENDPOINT_FRONTEND
    ? `https://${process.env.ENDPOINT_FRONTEND}`
    : 'http://local.twine.net';

  const requireLoginRender = (Component, url) => (props) =>
    isLoggedIn() ? (
      <Component {...props} />
    ) : (
      __CLIENT__ &&
      (window.location =
        url || `${signInUrl}/signin?referer=${props.location.pathname}`)
    );

  const noAuthRoutes = [
    {
      path: '/signin:query?',
      component: Login,
    },
    {
      path: '/signup',
      component: SignUp,
    },
  ];

  const authOnlyRoutes = [
    {
      path: '/onboarding/microprojects',
      component: MicroProjects,
    },
    {
      path: '/onboarding/notifications',
      component: OnboardingNotifications,
    },
    {
      path: '/onboarding',
      component: Onboarding,
    },
    {
      path: '/notifications',
      component: Notifications,
    },

    {
      // 'manage-briefs'
      path: '/manage/briefs/:briefId?/(pay)?/:pitchId?',
      component: ManageBriefsWrapper,
    },
    {
      // 'manage-pitches'
      path: '/manage/pitches/:pitchId?/(new-bill)?',
      component: ManagePitchesWrapper,
    },
    {
      // 'messages',
      path: '/messages/:username?',
      component: Messages,
    },
    {
      path: '/projects/:id/questions/:questionId',
      component: AnswerPublicQuestion,
    },
    {
      path: '/projects/create',
      component: CreateProject,
    },
    {
      path: '/:username/c:hash/:title?/text-only-edit',
      component: EditProject,
    },
    {
      path: '/projects/manage',
      component: ManageProjects,
    },
    {
      path: '/report/messages/:messageId',
      component: ReportMessages,
    },
    {
      path: '/shortlist',
      component: Shortlist,
    },
    {
      path: '/settings/:section?',
      component: Settings,
    },
    {
      path: '/payments',
      component: ViewPayments,
    },
    {
      path: '/earnings',
      component: ViewEarnings,
    },
    {
      path: '/users_integrations_oauth/undock',
      component: Undock,
    },
  ].map(({ component, ...rest }) => ({
    ...rest,
    component: requireLoginRender(component),
  }));

  const universalRoutes = [
    {
      path: '/',
      exact: true,
      // component: Home,
      component: isLoggedIn()
        ? () => (
            <Redirect
              to={user && user.type === 'buyer' ? 'manage/briefs' : 'jobs'}
            />
          )
        : Home,
    },
    {
      path: [
        '/jobs/:role?/(in)/:country/:city?',
        '/collaborate/:role?',
        '/jobs/:role?',
        '/jobs',
      ],
      exact: true,
      component: Projects,
    },
    {
      path: '/projects/:id?/:subsection?/:pitchId?',
      component: BriefDetailsWrapper,
    },
    {
      path: '/activate',
      component: Activate,
    },
    {
      path: '/browse',
      component: Browse,
    },
    {
      path: '/search',
      component: ({ location }) => (
        <Redirect
          to={{
            pathname: '/browse',
            search: location.search,
          }}
        />
      ),
    },
    {
      path: '/tag',
      component: () => <Redirect to="/browse" />,
    },
    {
      path: '/tag/%23:query',
      component: ({ location }) => (
        <Redirect to={`/browse/%23${location.query}`} />
      ),
    },
    {
      path: '/tag/:query',
      component: ({ location }) => (
        <Redirect to={`/browse/%23${location.query}`} />
      ),
    },
    {
      path: '/testimonials/:id/:authcode',
      component: TestimonialsUpdate,
    },
    {
      path: '/aup',
      component: Aup,
    },
    {
      path: '/engagement-terms',
      component: EngagementTerms,
    },
    {
      path: '/platform-terms',
      component: PlatformTerms,
    },
    {
      path: '/copyright',
      component: Copyright,
    },
    {
      path: '/howitworks/:tab?',
      component: HowItWorks,
    },
    {
      path: '/whyusetwine',
      component: WhyUseTwine,
    },
    {
      path: '/enterprise',
      component: Enterprise,
    },
    {
      path: '/hire-a-freelancer/:category?',
      component: Categories,
    },
    {
      path: '/post/:role?',
      component: Post,
    },
    {
      path: ['/find/:role?/with/:skill?', '/find/:role?/:country?/:city?'],
      render: FindRedirectWrapper,
    },
    {
      path: '/get/:role/:country?/:city?',
      component: ({ location }) => {
        const redirect = getRedirRoute('get', location);

        return (
          __CLIENT__ &&
          (window.location = redirect
            ? `${signInUrl}/${redirect.pathname}`
            : `${signInUrl}`)
        );
      },
    },
    {
      path: ['/pricing', '/pricing/creative'],
      component: Pricing,
    },
    {
      path: '/instagram/:skill',
      render: ({ match }) => (
        <Redirect
          to={`/get/${
            match.params.skill
          }?utm_source=instagram&utm_campaign=twine${match.params.skill.replace(
            /-/g,
            ''
          )}`}
        />
      ),
    },

    // Example: https://www.twine.net/LeifAndrew/c1qk7t0/views/views
    {
      path: '/:username/:hash/:project/views',
      render: ({ match }) => (
        <Redirect
          to={`/${match.params.username}/${match.params.hash}/${match.params.project}`}
        />
      ),
    },
    {
      path: '/:username/c:hash/:title?/:credits?',
      exact: true,
      component: Project,
    },
    {
      path: '/:username/:option?',
      exact: true,
      component: Portfolio,
    },
    {
      path: '/*',
      component: NotFound,
    },
  ];

  return [
    {
      component: App,
      routes: [...authOnlyRoutes, ...noAuthRoutes, ...universalRoutes],
    },
  ];
};
