import { fromJS, List } from 'immutable';
import briefSchema from '../schemas/briefSchema';
import creditSchema from '../schemas/creditSchema';
import testimonialSchema from '../schemas/testimonialSchema';
import shortlistSchema from '../schemas/shortlistSchema';
import userSchema from '../schemas/userSchema';
import { jsonToFormData } from '../../helpers/HelperFunctions';

const LOADUSER = 'twineApp/portfolio/LOADUSER';
const LOADUSER_SUCCESS = 'twineApp/portfolio/LOADUSER_SUCCESS';
const LOADUSER_FAIL = 'twineApp/portfolio/LOADUSER_FAIL';
const LOADBRIEFS = 'twineApp/portfolio/LOADBRIEFS';
const LOADBRIEFS_SUCCESS = 'twineApp/portfolio/LOADBRIEFS_SUCCESS';
const LOADBRIEFS_FAIL = 'twineApp/portfolio/LOADBRIEFS_FAIL';
const LOADOWNBRIEFS = 'twineApp/portfolio/LOADOWNBRIEFS';
const LOADOWNBRIEFS_SUCCESS = 'twineApp/portfolio/LOADOWNBRIEFS_SUCCESS';
const LOADOWNBRIEFS_FAIL = 'twineApp/portfolio/LOADOWNBRIEFS_FAIL';
const LOADFEATURED = 'twineApp/portfolio/LOADFEATURED';
const LOADFEATURED_SUCCESS = 'twineApp/portfolio/LOADFEATURED_SUCCESS';
const LOADFEATURED_FAIL = 'twineApp/portfolio/LOADFEATURED_FAIL';
const LOADCREDITS = 'twineApp/portfolio/LOADCREDITS';
const LOADCREDITS_SUCCESS = 'twineApp/portfolio/LOADCREDITS_SUCCESS';
const LOADCREDITS_FAIL = 'twineApp/portfolio/LOADCREDITS_FAIL';
const LOADUSERTOUR = 'twineApp/portfolio/LOADUSERTOUR';
const LOADUSERTOUR_SUCCESS = 'twineApp/portfolio/LOADUSERTOUR_SUCCESS';
const LOADUSERTOUR_FAIL = 'twineApp/portfolio/LOADUSERTOUR_FAIL';
const UPDATEUSERTOUR = 'twineApp/portfolio/UPDATEUSERTOUR';
const UPDATEUSERTOUR_SUCCESS = 'twineApp/portfolio/UPDATEUSERTOUR_SUCCESS';
const UPDATEUSERTOUR_FAIL = 'twineApp/portfolio/UPDATEUSERTOUR_FAIL';
const LOADTESTIMONIALS = 'twineApp/portfolio/LOADTESTIMONIALS';
const LOADTESTIMONIALS_SUCCESS = 'twineApp/portfolio/LOADTESTIMONIALS_SUCCESS';
const LOADTESTIMONIALS_FAIL = 'twineApp/portfolio/LOADTESTIMONIALS_FAIL';
const LOADSHORTLISTS = 'twineApp/portfolio/LOADSHORTLISTS';
const LOADSHORTLISTS_SUCCESS = 'twineApp/portfolio/LOADSHORTLISTS_SUCCESS';
const LOADSHORTLISTS_FAIL = 'twineApp/portfolio/LOADSHORTLISTS_FAIL';
const ISSHORTLISTING = 'twineApp/portfolio/ISSHORTLISTING';
const ISSHORTLISTING_SUCCESS = 'twineApp/portfolio/ISSHORTLISTING_SUCCESS';
const ISSHORTLISTING_FAIL = 'twineApp/portfolio/ISSHORTLISTING_FAIL';
const FEATURE = 'twineApp/portfolio/FEATURE';
const FEATURE_SUCCESS = 'twineApp/portfolio/FEATURE_SUCCESS';
const FEATURE_FAIL = 'twineApp/portfolio/FEATURE_FAIL';
const SHORTLIST = 'twineApp/portfolio/SHORTLIST';
const SHORTLIST_SUCCESS = 'twineApp/portfolio/SHORTLIST_SUCCESS';
const SHORTLIST_FAIL = 'twineApp/portfolio/SHORTLIST_FAIL';
const UNSHORTLIST = 'twineApp/portfolio/UNSHORTLIST';
const UNSHORTLIST_SUCCESS = 'twineApp/portfolio/UNSHORTLIST_SUCCESS';
const UNSHORTLIST_FAIL = 'twineApp/portfolio/UNSHORTLIST_FAIL';
const VERIFY = 'twineApp/portfolio/VERIFY';
const VERIFY_SUCCESS = 'twineApp/portfolio/VERIFY_SUCCESS';
const VERIFY_FAIL = 'twineApp/portfolio/VERIFY_FAIL';
const REORDERCREDIT = 'twineApp/portfolio/REORDERCREDIT';
const REORDERCREDIT_SUCCESS = 'twineApp/portfolio/REORDERCREDIT_SUCCESS';
const REORDERCREDIT_FAIL = 'twineApp/portfolio/REORDERCREDIT_FAIL';
const DELETEPROJECT = 'twineAPP/portfolio/DELETEPROJECT';
const DELETEPROJECT_SUCCESS = 'twineAPP/portfolio/DELETEPROJECT';
const DELETEPROJECT_FAIL = 'twineAPP/portfolio/DELETEPROJECT';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case LOADUSER:
      return imState.setIn([action.username, 'loadingUser'], true).toJS();

    case LOADUSER_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            id: action.result,
            loadedUser: true,
            loadingUser: false,
          },
        })
        .toJS();

    case LOADUSER_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedUser: true,
            loadingUser: false,
          },
        })
        .toJS();

    case LOADBRIEFS:
      return imState.setIn([action.username, 'loadingBriefs'], true).toJS();

    case LOADBRIEFS_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            briefs:
              Object.keys(action.result).length > 0
                ? action.result.collaborations
                : [],
            loadedBriefs: true,
            loadingBriefs: false,
          },
        })
        .toJS();

    case LOADBRIEFS_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedBriefs: true,
            loadingBriefs: false,
          },
        })
        .toJS();

    case LOADOWNBRIEFS:
      return imState.setIn([action.username, 'loadingOwnBriefs'], true).toJS();

    case LOADOWNBRIEFS_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            ownBriefs:
              Object.keys(action.result).length > 0
                ? action.result.collaborations
                : [],
            loadedOwnBriefs: true,
            loadingOwnBriefs: false,
          },
        })
        .toJS();

    case LOADOWNBRIEFS_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedOwnBriefs: true,
            loadingOwnBriefs: false,
          },
        })
        .toJS();

    case LOADFEATURED:
      return imState
        .setIn([action.username, 'loadingFeaturedCredits'], true)
        .toJS();

    case LOADFEATURED_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            featuredCredits:
              Object.keys(action.result).length > 0
                ? action.result.credits
                : [],
            loadedFeaturedCredits: true,
            loadingFeaturedCredits: false,
          },
        })
        .toJS();

    case LOADFEATURED_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedFeaturedCredits: true,
            loadingFeaturedCredits: false,
          },
        })
        .toJS();

    case LOADCREDITS:
      return imState.setIn([action.username, 'loadingCredits'], true).toJS();

    case LOADCREDITS_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            credits: imState
              .getIn([action.username, 'credits'], List())
              .concat(
                typeof action.result.credits !== 'undefined'
                  ? action.result.credits
                  : []
              ),
            loadedCredits: true,
            loadingCredits: false,
            moreCredits:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.current_page <
                  action.result.meta.pagination.total_pages
                : false,
            creditPagesLoaded:
              imState.getIn([action.username, 'creditPagesLoaded'], 0) + 1,
          },
        })
        .toJS();

    case LOADCREDITS_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedCredits: true,
            loadingCredits: false,
          },
        })
        .toJS();

    case LOADUSERTOUR:
      return imState.setIn([action.username, 'loadingUserTour'], true).toJS();

    case LOADUSERTOUR_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedUserTour: true,
            loadingUserTour: false,
            tour:
              typeof action.result.tour !== 'undefined'
                ? action.result.tour
                : {},
          },
        })
        .toJS();

    case LOADUSERTOUR_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedUserTour: true,
            loadingUserTour: false,
          },
        })
        .toJS();

    case UPDATEUSERTOUR:
      return imState.setIn([action.username, 'loadingUserTour'], true).toJS();

    case UPDATEUSERTOUR_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedUserTour: true,
            loadingUserTour: false,
            tour:
              typeof action.result.tour !== 'undefined'
                ? action.result.tour
                : {},
          },
        })
        .toJS();

    case UPDATEUSERTOUR_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedUserTour: true,
            loadingUserTour: false,
          },
        })
        .toJS();

    case LOADTESTIMONIALS:
      return imState
        .setIn([action.username, 'loadingTestimonials'], true)
        .toJS();

    case LOADTESTIMONIALS_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedTestimonials: true,
            loadingTestimonials: false,
            moreTestimonials:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.current_page <
                  action.result.meta.pagination.total_pages
                : false,
            testimonialTotal:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.total
                : 0,
            testimonialPagesLoaded:
              imState.getIn([action.username, 'testimonialPagesLoaded'], 0) + 1,
            testimonials: imState
              .getIn([action.username, 'testimonials'], List())
              .concat(
                typeof action.result.testimonials !== 'undefined'
                  ? action.result.testimonials
                  : []
              ), // eslint-disable-line new-cap
          },
        })
        .toJS();

    case LOADTESTIMONIALS_FAIL:
      return imState
        .setIn([action.username, 'loadingTestimonials'], false)
        .toJS();

    case LOADSHORTLISTS:
      return imState.setIn([action.username, 'loadingShortlists'], true).toJS();

    case LOADSHORTLISTS_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            loadedShortlists: true,
            loadingShortlists: false,
            moreShortlists:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.current_page <
                  action.result.meta.pagination.total_pages
                : false,
            shortlistTotal:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.total
                : 0,
            shortlistPagesLoaded:
              imState.getIn([action.username, 'shortlistPagesLoaded'], 0) + 1,
            shortlist: imState
              .getIn([action.username, 'shortlist'], List())
              .concat(
                typeof action.result.followings !== 'undefined'
                  ? action.result.followings
                  : []
              ), // eslint-disable-line new-cap
          },
        })
        .toJS();

    case LOADSHORTLISTS_FAIL:
      return imState
        .setIn([action.username, 'loadingShortlists'], false)
        .toJS();

    case ISSHORTLISTING:
      return imState
        .setIn([action.username, 'loadingShortlistState'], true)
        .toJS();

    case ISSHORTLISTING_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            isShortlisted: action.result.following,
            loadingShortlistState: false,
          },
        })
        .toJS();

    case ISSHORTLISTING_FAIL:
      return imState
        .setIn([action.username, 'loadingShortlistState'], false)
        .toJS();

    // Optimistic load
    case SHORTLIST:
      return imState
        .mergeDeep({
          [action.username]: {
            isShortlisted: true,
          },
        })
        .toJS();

    case SHORTLIST_SUCCESS:
      return imState.toJS();

    case SHORTLIST_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            isShortlisted: false,
          },
        })
        .toJS();

    // Optimistic load
    case UNSHORTLIST:
      return imState
        .mergeDeep({
          [action.username]: {
            isShortlisted: false,
          },
        })
        .toJS();

    case UNSHORTLIST_SUCCESS:
      return imState.toJS();

    case UNSHORTLIST_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            isShortlisted: true,
          },
        })
        .toJS();

    // Optimistic load
    case FEATURE:
      return imState
        .setIn([action.username, 'featuredCredits'], List([action.credit.id]))
        .toJS();

    case FEATURE_SUCCESS:
      return imState.toJS();

    case FEATURE_FAIL:
      return imState.toJS();

    // Optimistic load
    case VERIFY:
      return imState
        .mergeDeep({
          [action.username]: {
            isVerified: true,
          },
        })
        .toJS();

    case VERIFY_SUCCESS:
      return imState.toJS();

    case VERIFY_FAIL:
      return imState
        .mergeDeep({
          [action.username]: {
            isVerified: false,
          },
        })
        .toJS();

    case REORDERCREDIT:
      return imState
        .setIn([action.username, 'isReorderingCredits'], true)
        .toJS();

    case REORDERCREDIT_SUCCESS:
      return imState
        .mergeDeep({
          [action.username]: {
            isReorderingCredits: false,
            credits: imState
              .getIn([action.username, 'credits'], List())
              .filter((creditId) => creditId !== action.creditId)
              .splice(action.newPosition - 1, 0, action.creditId),
          },
        })
        .toJS();

    case REORDERCREDIT_FAIL:
      return imState
        .setIn([action.username, 'isReorderingCredits'], false)
        .toJS();

    default:
      return imState.toJS();
  }
}

export function isUserLoaded(globalState, username) {
  return (
    globalState.portfolio &&
    typeof globalState.portfolio[username] !== 'undefined' &&
    typeof globalState.portfolio[username].loadedUser !== 'undefined' &&
    globalState.portfolio[username].loadedUser
  );
}

export function loadUser(username) {
  return {
    types: [LOADUSER, LOADUSER_SUCCESS, LOADUSER_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          username +
          '?include=avatars,covers,skills,stats,sectors,tools,education,language&page=portfolio'
      ),
    username,
    schema: userSchema,
  };
}

export function isBriefsLoaded(globalState, username) {
  return (
    globalState &&
    typeof globalState.portfolio[username] !== 'undefined' &&
    typeof globalState.portfolio[username].loadedBriefs !== 'undefined' &&
    globalState.portfolio[username].loadedBriefs
  );
}

export function loadBriefs(username) {
  return {
    types: [LOADBRIEFS, LOADBRIEFS_SUCCESS, LOADBRIEFS_FAIL],
    promise: (client) =>
      client.get('/users/' + username + '/notices/collaboration'),
    username,
    schema: { collaborations: [briefSchema] },
  };
}

export function isOwnBriefsLoaded(globalState, username) {
  return (
    globalState &&
    typeof globalState.portfolio[username] !== 'undefined' &&
    typeof globalState.portfolio[username].loadedOwnBriefs !== 'undefined' &&
    globalState.portfolio[username].loadedOwnBriefs
  );
}

export function deleteProject(projectId) {
  return {
    types: [DELETEPROJECT, DELETEPROJECT_SUCCESS, DELETEPROJECT_FAIL],
    promise: (client) => client.delete('/projects/' + projectId),
  };
}

export function loadOwnBriefs(username) {
  return {
    types: [LOADOWNBRIEFS, LOADOWNBRIEFS_SUCCESS, LOADOWNBRIEFS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          username +
          '/notices/collaboration?state=active,private,pending_approval&include_state=1'
      ),
    username,
    schema: { collaborations: [briefSchema] },
  };
}

export function isFeaturedLoaded(globalState, username) {
  return (
    globalState &&
    typeof globalState.portfolio[username] !== 'undefined' &&
    typeof globalState.portfolio[username].loadedFeaturedCredits !==
      'undefined' &&
    globalState.portfolio[username].loadedFeaturedCredits
  );
}

export function loadFeatured(username) {
  return {
    types: [LOADFEATURED, LOADFEATURED_SUCCESS, LOADFEATURED_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          username +
          '/credits/featured?include=project,project.thumbnails'
      ),
    username,
    schema: { credits: [creditSchema] },
  };
}

export function isCreditsLoaded(globalState, username) {
  return (
    globalState &&
    typeof globalState.portfolio[username] !== 'undefined' &&
    typeof globalState.portfolio[username].loadedCredits !== 'undefined' &&
    globalState.portfolio[username].loadedCredits
  );
}

export function loadCredits(username, page = 1, all = false) {
  return {
    types: [LOADCREDITS, LOADCREDITS_SUCCESS, LOADCREDITS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          username +
          `/credits?active=1&parents_only=1&include=project,project.thumbnails${
            all ? '' : `&limit=12&page=${page}`
          }`
      ),
    username,
    schema: { credits: [creditSchema] },
  };
}

export function isUserTourLoaded(globalState, username) {
  return (
    globalState.portfolio &&
    typeof globalState.portfolio[username] !== 'undefined' &&
    typeof globalState.portfolio[username].loadedUserTour !== 'undefined' &&
    globalState.portfolio[username].loadedUserTour
  );
}

export function loadUserTour(username) {
  return {
    types: [LOADUSERTOUR, LOADUSERTOUR_SUCCESS, LOADUSERTOUR_FAIL],
    promise: (client) => client.get('/users/' + username + '?include=tour'),
    username,
  };
}

export function updateUserTour(username, tourData) {
  return {
    types: [UPDATEUSERTOUR, UPDATEUSERTOUR_SUCCESS, UPDATEUSERTOUR_FAIL],
    promise: (client) =>
      client.patch(`/users/${username}?include=tour`, {
        data: {
          tour: tourData,
        },
      }),
  };
}

export function isTestimonialsLoaded(globalState, username) {
  return (
    globalState &&
    typeof globalState.portfolio[username] !== 'undefined' &&
    typeof globalState.portfolio[username].loadedTestimonials !== 'undefined' &&
    globalState.portfolio[username].loadedTestimonials
  );
}

export function loadTestimonials(username, page = 1) {
  return {
    types: [LOADTESTIMONIALS, LOADTESTIMONIALS_SUCCESS, LOADTESTIMONIALS_FAIL],
    promise: (client) =>
      client.get('/users/' + username + '/testimonials?page=' + page),
    username,
    schema: { testimonials: [testimonialSchema] },
  };
}

export function isShortlistsLoaded(globalState, username) {
  return (
    globalState &&
    typeof globalState.portfolio[username] !== 'undefined' &&
    typeof globalState.portfolio[username].loadedShortlists !== 'undefined' &&
    globalState.portfolio[username].loadedShortlists
  );
}

export function loadShortlists(username, page = 1, limit = 10) {
  return {
    types: [LOADSHORTLISTS, LOADSHORTLISTS_SUCCESS, LOADSHORTLISTS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          username +
          '/following?include=user.stats,user.avatars,user.covers&page=' +
          page +
          '&limit=' +
          limit
      ),
    username,
    schema: { shortlist: [shortlistSchema] },
  };
}

export function isShortlisted(username) {
  return {
    types: [ISSHORTLISTING, ISSHORTLISTING_SUCCESS, ISSHORTLISTING_FAIL],
    promise: (client) => client.get(`/users/${username}/follow`),
    username,
  };
}

export function shortlist(username) {
  return {
    types: [SHORTLIST, SHORTLIST_SUCCESS, SHORTLIST_FAIL],
    promise: (client) => client.put(`/users/${username}/follow`),
    username,
  };
}

export function unshortlist(username) {
  return {
    types: [UNSHORTLIST, UNSHORTLIST_SUCCESS, UNSHORTLIST_FAIL],
    promise: (client) => client.delete(`/users/${username}/follow`),
    username,
  };
}

export function makeFeatured(username, credit) {
  return {
    types: [FEATURE, FEATURE_SUCCESS, FEATURE_FAIL],
    promise: (client) => client.put(`/projects/${credit.project.id}/feature`),
    username,
    credit,
  };
}

export function verify(username, url) {
  return {
    types: [VERIFY, VERIFY_SUCCESS, VERIFY_FAIL],
    promise: (client) =>
      client.post(`/users/${username}/verify`, {
        data: jsonToFormData({
          url: url,
        }),
      }),
    username,
  };
}

export function reorderCredits(username, creditId, newPosition) {
  return {
    types: [REORDERCREDIT, REORDERCREDIT_SUCCESS, REORDERCREDIT_FAIL],
    promise: (client) =>
      client.patch(`/credits/${creditId}/updateTag`, {
        data: {
          order: newPosition,
        },
      }),
    username,
    creditId,
    newPosition,
  };
}
