import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap/lib';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import BriefInvitePush from 'components/Banners/BriefInvitePush';
import PromotePushNotifications from 'components/Banners/PromotePushNotifications';
import EmailConfirmation from 'components/Banners/EmailConfirmation';
import EmailActivation from 'components/Banners/EmailActivation';
import Onboarding from 'components/Banners/Onboarding/Onboarding';
import { remove as removeBanner } from 'redux/modules/banner';
import styles from './Banner.module.scss';

const Banner = ({ banners, removeBanner }) => {
  const activeBannerRef = useRef(null);
  const [overflow, setOverflow] = useState(false);

  let banner = {
    priority: 0,
  };

  banners.forEach((checkBanner) => {
    if (parseInt(banner.priority, 10) < parseInt(checkBanner.priority, 10)) {
      banner = checkBanner;
    }
  });

  useEffect(() => {
    if (banner.tag) {
      setTimeout(() => {
        setOverflow(true);
      }, 500);
    }
  }, [banner.tag]);

  const getComponent = (banner) => {
    if (banner.tag === 'brief_invite_push') {
      return (
        <BriefInvitePush
          ref={activeBannerRef}
          banner={banner}
          removeBanner={removeBanner}
        />
      );
    } else if (banner.tag === 'promote_push_notifications') {
      return (
        <PromotePushNotifications
          ref={activeBannerRef}
          banner={banner}
          removeBanner={removeBanner}
        />
      );
    } else if (banner.tag === 'email_confirmation') {
      return (
        <EmailConfirmation
          ref={activeBannerRef}
          banner={banner}
          removeBanner={removeBanner}
        />
      );
    } else if (banner.tag === 'email_not_verified') {
      return <EmailActivation ref={activeBannerRef} banner={banner} />;
    } else if (banner.tag === 'onboarding') {
      return (
        <Onboarding
          innerRef={activeBannerRef}
          banner={banner}
          removeBanner={removeBanner}
        />
      );
    }
  };

  const close = (banner) => {
    if (activeBannerRef?.current && activeBannerRef?.current?.onClose) {
      activeBannerRef.current.onClose();
    }

    removeBanner(banner.tag);
  };

  // Onboarding Banner Rendering
  if (banner.tag === 'onboarding') {
    return getComponent(banner);
  }

  const classes = [
    styles.banner,
    banner.color ? styles[banner.color] : null,
    banner.tag ? styles.open : styles.closed,
    overflow ? styles.noOverflow : null,
  ].join(' ');

  return (
    <Col id="static-banner" className={classes}>
      {banner.closeable && (
        <div className={styles.closeButton} onClick={() => close(banner)}>
          <SvgIcon icon="close" color="white" size="20" />
        </div>
      )}
      <div className={styles.message}>
        {getComponent(banner) || banner.message || banner.tag}
      </div>
    </Col>
  );
};

Banner.propTypes = {
  banners: PropTypes.array,
  removeBanner: PropTypes.func,
};

const mapStateToProps = ({ banner }) => ({
  banners: banner.banners,
});

export default connect(mapStateToProps, { removeBanner })(Banner);
