const ACTIVATE_REQUEST = 'twineApp/activate/ACTIVATE_REQUEST';
const ACTIVATE_SUCCESS = 'twineApp/activate/ACTIVATE_SUCCESS';
const ACTIVATE_FAILURE = 'twineApp/activate/ACTIVATE_FAILURE';

const initialState = {
  loading: false,
  error: null,
  success: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIVATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTIVATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ACTIVATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function activateAccount(key) {
  return {
    types: [ACTIVATE_REQUEST, ACTIVATE_SUCCESS, ACTIVATE_FAILURE],
    promise: (client) => client.get(`/users/activate?key=${key}`),
    ignoreError: true,
  };
}
