import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import auth from './auth';
import banner from './banner';
import briefDetails from './briefDetails';
import briefs from './briefs';
import briefs2 from './briefs2';
import browse from './browse';
import entities from './entities';
import errorMessage from './errorMessage';
import highlightElement from './highlightElement';
import landingPage from './landingPage';
import landingPages from './landingPages';
import manageBriefs from './manageBriefs';
import managePitches from './managePitches';
import messages from './messages';
import notifications from './notifications';
import onboarding from './onboarding';
import onePageWebsite from './onePageWebsite';
import partners from './partners';
import payments from './payments';
import paymentDetails from './paymentDetails';
import pitches from './pitches';
import pitchWriter from './pitchWriter';
import portfolio from './portfolio';
import project from './project';
import projects from './projects';
import projects2 from './projects2';
import publicQuestions from './publicQuestions';
import publicQuestions2 from './publicQuestions2';
import rating from './rating';
import reports from './reports';
import settings from './settings';
import skills from './skills';
import successMessage from './successMessage';
import suggestedRoles from './suggestedRoles';
import suggestedRoles2 from './suggestedRoles2';
import suggestedSkills from './suggestedSkills';
import suggestedSkills2 from './suggestedSkills2';
import subscriptions from './subscriptions';
import testimonials from './testimonials';
import textProjects from './textProjects';
import users from './users';
import stats from './stats';
import briefCard from './briefCard';
import bills from './bills';
import websockets from './websockets';
import undock from './undock';
import companies from './companies';
import cities from './cities';
import jobs from './jobs';
import activate from './activate';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    reduxAsyncConnect,
    auth,
    activate,
    banner,
    bills,
    briefCard,
    briefDetails,
    briefs,
    briefs2,
    browse,
    companies,
    errorMessage,
    highlightElement,
    landingPage,
    landingPages,
    manageBriefs,
    managePitches,
    messages,
    notifications,
    onboarding,
    onePageWebsite,
    partners,
    payments,
    paymentDetails,
    pitches,
    pitchWriter,
    portfolio,
    project,
    projects,
    projects2,
    publicQuestions,
    publicQuestions2,
    rating,
    reports,
    settings,
    skills,
    stats,
    successMessage,
    suggestedRoles,
    suggestedRoles2,
    suggestedSkills,
    suggestedSkills2,
    subscriptions,
    testimonials,
    textProjects,
    undock,
    users,
    websockets,
    cities,
    jobs,
    // ENTITIES MUST ALWAYS BE LAST
    entities,
  });
